.Loading {
  text-align: center;
  position: relative;
  min-height: 100vh;
}

.Loading-icon {
  padding-top: 40vh;
  width: 60vw;
  max-width: 270px;
  object-fit: contain;
  animation-name: load;
  animation-duration: 1.5s;
  animation-iteration-count: infinite
}

.Loading-text {
  padding-top: 10px;
  text-align: center;
  animation-name: load;
  animation-duration: 1.5s;
  animation-iteration-count: infinite
}

@keyframes load {
  0%   {opacity: 0.1;}
  10%  {opacity: 0.2;}
  20%  {opacity: 0.4;}
  30%  {opacity: 0.6;}
  40%  {opacity: 0.8;}
  50%  {opacity: 0.8;}
  60%  {opacity: 0.8;}
  70%  {opacity: 0.6;}
  80%  {opacity: 0.4;}
  90%  {opacity: 0.2;}
  100% {opacity: 0.1;}
}